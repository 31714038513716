//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useProduct } from "~/composables";
import { useUser } from "~/modules/customer/composables/useUser";

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { getProductPath } = useProduct();
    const { isAuthenticated } = useUser();

    return {
      getProductPath,
      isAuthenticated,
    };
  },
};
