//
//
//
//
//
//
//

import { makeMyLastWordBold } from "~/composables/makeLastWordBold";
export default {
  props: {
    title: {
      type: String,
      default: () => "",
    },
    removeHrLine: {
      type: Boolean,
      default: () => false,
      require: () => false,
    },
  },

  setup(props) {
    const pageTitle = props.title;

    return {
      // eslint-disable-next-line vue/no-dupe-keys
      pageTitle,
      makeMyLastWordBold,
    };
  },
};
