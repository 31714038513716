var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeCategoryName),expression:"activeCategoryName"}],staticClass:"container-full"},[(
      (_vm.activeCategory &&
        _vm.activeCategory.category_color &&
        _vm.products &&
        _vm.products.length > 0) ||
      (_vm.activeCategory && _vm.activeCategory.category_color && _vm.filterIsTriggered)
    )?_c('div',[_c('div',{staticClass:"category-top",style:({ backgroundColor: _vm.activeCategory.category_color })},[(_vm.activeCategory && _vm.activeCategory.description)?_c('div',{staticClass:"description container",domProps:{"innerHTML":_vm._s(_vm.activeCategory.description)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"category-top-image container"},[_c('Moments',{key:_vm.activeCategory.id,attrs:{"cat":_vm.activeCategory.id}})],1)]):_vm._e(),_vm._v(" "),(
      _vm.activeCategory &&
      !_vm.activeCategory.category_color &&
      _vm.products &&
      _vm.products.length > 0
    )?_c('div',[(_vm.activeCategory && _vm.activeCategory.description)?_c('div',{staticClass:"category-top no-bg-color",style:(_vm.inlineBgImage(_vm.activeCategory.image))},[(_vm.activeCategory && _vm.activeCategory.description)?_c('div',{staticClass:"description container",domProps:{"innerHTML":_vm._s(_vm.activeCategory.description)}}):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"category"}},[(_vm.isShowCms)?_c('CmsContent',{attrs:{"content":_vm.cmsContent}}):_vm._e(),_vm._v(" "),(!_vm.activeCategoryName)?_c('SkeletonLoader',{attrs:{"height":"57px","width":"200px","margin":"0"}}):_c('PageTitle',{attrs:{"title":_vm.activeCategoryName}}),_vm._v(" "),_c('div',{staticClass:"category-layout container"},[((_vm.products && _vm.products.length > 0) || _vm.filterIsTriggered)?_c('div',{staticClass:"sidebar column"},[(_vm.isShowProducts)?_c('CategoryFilters',{staticClass:"mobile-only",attrs:{"is-visible":_vm.isFilterSidebarOpen,"cat-uid":_vm.routeData.uid},on:{"close":function($event){return _vm.setNoScrollOnBody(false)},"reloadProducts":_vm.onReloadProducts,"filtersAreTriggered":function($event){return _vm.onFiltersTrigger($event)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isFilterSidebarOpen)?_c('BackDrop',{staticClass:"smartphone-only",on:{"closeModal":function($event){return _vm.setNoScrollOnBody(false)}}}):_vm._e(),_vm._v(" "),_c('div',{ref:"productContainerElement",staticClass:"main section column"},[(
            (_vm.products &&
              _vm.products.length > 0 &&
              _vm.activeCategory &&
              _vm.activeCategory.short_description) ||
            (_vm.activeCategory &&
              _vm.activeCategory.short_description &&
              _vm.filterIsTriggered)
          )?_c('ShortDescription',{attrs:{"shortDescription":_vm.activeCategory.short_description}}):_vm._e(),_vm._v(" "),(
            (_vm.isShowProducts && _vm.products && _vm.products.length > 0) ||
            _vm.filterIsTriggered
          )?_c('CategoryNavbar',{attrs:{"sort-by":_vm.sortBy,"pagination":_vm.pagination,"is-loading":_vm.$fetchState.pending},on:{"reloadProducts":_vm.onReloadProducts,"openFilters":_vm.setNoScrollOnBody}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"products"},[(
              _vm.products.length === 0 && !_vm.$fetchState.pending && _vm.isShowProducts
            )?_c('CategoryEmptyResults',{attrs:{"showFoltersNoProducst":_vm.filterIsTriggered}}):_vm._e(),_vm._v(" "),(_vm.isCategoryGridView)?_c('CategoryProductGrid',{attrs:{"products":_vm.products,"prices-loaded":_vm.isPriceLoaded,"loading":_vm.$fetchState.pending},on:{"click:wishlist":_vm.addItemToWishlist,"click:add-to-cart":_vm.addItemToCart}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"products__display-opt"},[_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('CategoryPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagination.totalPages > 1),expression:"pagination.totalPages > 1"}],staticClass:"products__pagination",attrs:{"current":_vm.pagination.currentPage,"total":_vm.pagination.totalPages,"visible":5},on:{"click":function($event){return _vm.goToPage($event)}},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
            var go = ref.go;
            var prev = ref.prev;
            var isDisabled = ref.isDisabled;
return [_c('div',{staticClass:"pagination-btn pagination-prev",attrs:{"disabled":isDisabled},on:{"click":function($event){isDisabled ? '' : go(prev)}}},[_c('Icon',{attrs:{"icon":"bi:arrow-left"}}),_vm._v(" "),_c('p',{staticClass:"pag-text-prev"},[_vm._v(_vm._s(_vm.$t("Prev page")))])],1)]}},{key:"next",fn:function(ref){
            var go = ref.go;
            var next = ref.next;
            var isDisabled = ref.isDisabled;
return [_c('div',{staticClass:"pagination-btn pagination-next",attrs:{"disabled":isDisabled},on:{"click":function($event){isDisabled ? '' : go(next)}}},[_c('p',{staticClass:"pag-text-next"},[_vm._v(_vm._s(_vm.$t("Next page")))]),_vm._v(" "),_c('Icon',{attrs:{"icon":"bi:arrow-right"}})],1)]}}])})],1),_vm._v(" "),(_vm.pagination.totalPages > 1)?_c('div',{staticClass:"products__show-on-page"},[_c('span',{staticClass:"products__show-on-page__label"},[_vm._v(_vm._s(_vm.$t("Show")))]),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('SfSelect',{staticClass:"products__items-per-page",attrs:{"value":_vm.pagination.itemsPerPage.toString()},on:{"input":_vm.doChangeItemsPerPage}},_vm._l((_vm.pagination.pageOptions),function(option){return _c('SfSelectOption',{key:option,staticClass:"products__items-per-page__option",attrs:{"value":option}},[_vm._v("\n              "+_vm._s(option)+"\n            ")])}),1)],1)],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }