












































import { defineComponent, PropType } from "@nuxtjs/composition-api";
import { SfSelect, SfButton } from "@storefront-ui/vue";
import SvgImage from "~/components/General/SvgImage.vue";
import { useUiHelpers, useUiState } from "~/composables";
import { Pagination } from "~/composables/types";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { SortingModel } from "~/modules/catalog/category/composables/useFacet/sortingOptions";

export default defineComponent({
  components: {
    SkeletonLoader,
    SvgImage,
    SfSelect,
    SfButton,
  },
  props: {
    sortBy: {
      type: Object as PropType<SortingModel>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
    } = useUiState();
    const uiHelpers = useUiHelpers();
    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      emit("reloadProducts");
    };
    const toggleFilterS = () => {
      emit("openFilters", true);
      toggleFilterSidebar();
    };
    return {
      toggleFilterSidebar,
      doChangeSorting,
      ...uiHelpers,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      toggleFilterS,
    };
  },
});
