






import { defineComponent, computed } from "@nuxtjs/composition-api";
import { usePageStore } from "~/stores/page";

import CATEGORY from "~/modules/catalog/pages/category.vue";
import CMS_PAGE from "~/pages/Cms.vue";
import PRODUCT from "~/modules/catalog/pages/product.vue";
import STORYBLOK from "~/pages/Storyblok.vue";

export default defineComponent({
  name: "ThePageResolver",
  components: {
    CATEGORY,
    CMS_PAGE,
    PRODUCT,
    STORYBLOK,
  },
  middleware: ["url-resolver"],
  setup() {
    const { routeData } = usePageStore();

    const type = computed(() => routeData?.type);
    return {
      type,
    };
  },
});
