//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LazyHydrate from "vue-lazy-hydration";
import { SfSelect } from "@storefront-ui/vue";
import { ref } from "@nuxtjs/composition-api";
import EducationCategoryProductCard from "./EducationCategoryProductCard.vue";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

export default {
  props: {
    activeCategory: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    sortBy: {
      type: Object,
      required: true,
    },
  },
  components: {
    LazyHydrate,
    SfSelect,
    EducationCategoryProductCard,
    SkeletonLoader,
  },
  setup() {
    const itemsPerPage = ref("8");
    const pageOptions = ref(["8", "16", "24"]);
    const sortBySelect = ref("");

    return {
      itemsPerPage,
      pageOptions,
      sortBySelect,
    };
  },
};
