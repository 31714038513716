












































import { defineComponent, ref } from "@nuxtjs/composition-api";
import { useConfigStore } from "~/stores/config";

export default defineComponent({
  props: ["blok"],
  setup() {
    const mute = ref(true);
    const toggleSound = () => {
      mute.value = !mute.value;
    };
    return {
      mute,
      toggleSound,
    };
  },
});
