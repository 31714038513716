










import { computed, defineComponent, ref } from "@nuxtjs/composition-api";

type ComponentStyles = {
  width?: string;
  height?: string;
  borderRadius: string;
  margin: string;
};

export default defineComponent({
  name: "SkeletonLoader",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: [String, Boolean],
      default: "shimmer",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    margin: {
      type: String,
      default: "0 0 1em 0",
    },
    radius: {
      type: String,
      default: "4px",
    },
    skeletonClass: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const componentClass = computed(() => [
      "skeleton",
      props.skeletonClass,
      props.animation ? `skeleton--${props.animation}` : null,
    ]);

    const componentStyle = ref<ComponentStyles>({
      borderRadius: props.radius,
      margin: props.margin,
    });

    if (props.width) {
      componentStyle.value.width = props.width;
    }

    if (props.height) {
      componentStyle.value.height = props.height;
    }

    return {
      componentClass,
      componentStyle,
    };
  },
});
