//
//
//
//
//
//
//
//

export default {
    props: {
        shortDescription: {
            type: String
        },
    },
    computed: {
    trimmedShortDescription() {
        return this.shortDescription ? this.shortDescription.trim() : '';
    }
}
};
