









import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
import useStoryblok from "~/composables/useStoryblok";
import { useConfigStore } from "~/stores/config";

export default defineComponent({
  props: {
    cat: {
      type: Number,
    },
  },
  setup(props) {
    const storyblokData = ref(null);
    const { getStoryblokData } = useStoryblok();
    useFetch(async () => {
      if (props.cat) {
        await getStoryblokData(`moments-${props.cat}`).then((res) => {
          storyblokData.value = res;
        });
      }
    });

    return {
      storyblokData,
    };
  },
});
