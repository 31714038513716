//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from "@nuxtjs/composition-api";
export default defineComponent({
  name: "TheCustomBreadcrumbs",
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const breadcrumbsWithoutLastElement = (arr) => {
      if (arr.length > 2) {
        return arr.slice(1, -1);
      } else {
        return arr.slice(0, 1);
      }
    };

    return {
      breadcrumbsWithoutLastElement,
    };
  },
});
