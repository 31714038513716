//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ref,
  useFetch,
  useContext,
  useRoute,
  computed,
} from "@nuxtjs/composition-api";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import useStoryblok from "~/composables/useStoryblok";

export default {
  setup() {
    const { app, error } = useContext();
    const route = useRoute();
    const storyBlokData = ref(null);

    const baseUrl = app.$config.baseUrl;
    const locale = route.value.fullPath;

    const { getStoryblokData } = useStoryblok();

    const metatags = computed(() => storyBlokData?.value?.metatags);

    useFetch(async () => {
      await getStoryblokData(route.value.params.slug).then((res) => {
        if (res.story) {
          storyBlokData.value = res.story.content;
        } else {
          error({ statusCode: 404 });
        }
      });
    });
    return {
      storyBlokData,
      metatags,
      baseUrl,
      locale,
    };
  },
  head() {
    return getMetaInfo(this.metatags, this.locale, this.baseUrl);
  },
};
